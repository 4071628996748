import { TypographyVariantsOptions } from '@mui/material/styles/';

import '@mui/material/styles';
import '@mui/material/Typography';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    bodySmallRegular: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
    bodyMediumRegular: React.CSSProperties;
    bodyMediumMedium: React.CSSProperties;
    bodySmallSemibold: React.CSSProperties;
    bodyMediumSemibold: React.CSSProperties;
    bodyLargeSemibold: React.CSSProperties;
    bodyLargeMedium: React.CSSProperties;
    captionXSmall: React.CSSProperties;
    captionSmall: React.CSSProperties;
    captionLarge: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    h7Bold: React.CSSProperties;
    h7Semibold: React.CSSProperties;
    inputLabel: React.CSSProperties;
    display01Bold: React.CSSProperties;
    display02Bold: React.CSSProperties;
    bodyOnboardingsmall: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodySmallRegular?: React.CSSProperties;
    bodySmallMedium?: React.CSSProperties;
    bodyMediumRegular?: React.CSSProperties;
    bodyMediumMedium?: React.CSSProperties;
    bodySmallSemibold?: React.CSSProperties;
    bodyMediumSemibold?: React.CSSProperties;
    bodyLargeSemibold?: React.CSSProperties;
    bodyLargeMedium: React.CSSProperties;
    captionXSmall?: React.CSSProperties;
    captionSmall?: React.CSSProperties;
    captionLarge?: React.CSSProperties;
    h4Bold?: React.CSSProperties;
    h6Bold?: React.CSSProperties;
    h7Bold?: React.CSSProperties;
    h7Semibold?: React.CSSProperties;
    inputLabel?: React.CSSProperties;
    display01Bold?: React.CSSProperties;
    display02Bold: React.CSSProperties;
    bodyOnboardingsmall: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodySmallRegular: true;
    bodySmallMedium: true;
    bodyMediumRegular: true;
    bodyMediumMedium: true;
    bodySmallSemibold: true;
    bodyMediumSemibold: true;
    bodyLargeSemibold: true;
    bodyLargeMedium: true;
    captionXSmall: true;
    captionSmall: true;
    captionLarge: true;
    h4Bold: true;
    h6Bold: true;
    h7Bold: true;
    h7Semibold: true;
    inputLabel: true;
    display01Bold: true;
    display02Bold: true;
    bodyOnboardingsmall: true;
  }
}

const defaultBodyFontFamily = 'Inter';
const defaultHeaderFontFamily = 'Switzer';

export const TypographyTheme: TypographyVariantsOptions = {
  fontSize: 14,
  fontFamily: ['Switzer', 'Inter'].join(','),
  button: {
    fontSize: '.875rem',
    textTransform: 'none',
    '.css-oqhdza-MuiButtonBase-root-MuiButton-root:hover': {
      backgroundColor: 'none'
    }
  },
  h4: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.4rem',
    letterSpacing: '-0.05rem'
  },
  h5: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '1.8rem',
    letterSpacing: '-0.0225rem'
  },
  h6: {
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '1.35rem',
    letterSpacing: '-0.01688rem'
  },
  bodySmallMedium: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.26875rem',
    letterSpacing: '-0.0225rem',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodyMediumMedium: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.225rem',
    letterSpacing: '-0.21px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodySmallRegular: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1.225rem',
    letterSpacing: '-0.18px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodyMediumRegular: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.225rem',
    letterSpacing: '-0.35px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodySmallSemibold: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.44rem',
    letterSpacing: '-0.3px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodyMediumSemibold: {
    fontSize: '0.875rem',
    fontWeight: 600,
    lineHeight: '1.44rem',
    letterSpacing: '-0.21px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodyLargeSemibold: {
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.48rem ',
    letterSpacing: '-0.4px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },
  bodyLargeMedium: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.44rem',
    letterSpacing: '-0.4px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },

  bodyOnboardingsmall: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.44rem',
    letterSpacing: '-0.02rem',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily
  },

  captionXSmall: {
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1.05rem',
    letterSpacing: '0.4px',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily,
    textTransform: 'uppercase'
  },
  captionSmall: {
    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: '1.05rem',
    letterSpacing: '0.03rem',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily,
    textTransform: 'uppercase'
  },
  captionLarge: {
    fontSize: '0.85rem',
    fontWeight: 600,
    lineHeight: '1.05rem',
    letterSpacing: '0.07rem',
    fontStyle: 'normal',
    fontFamily: defaultBodyFontFamily,
    textTransform: 'uppercase'
  },
  h4Bold: {
    fontSize: '1.75rem',
    fontWeight: 700,
    lineHeight: '1.05rem',
    letterSpacing: '-0.04375rem',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  },
  h6Bold: {
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.05rem',
    letterSpacing: '-0.01875rem',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  },
  h7Bold: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '1.35rem',
    letterSpacing: ' -0.01125rem',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  },
  h7Semibold: {
    fontSize: '1.125rem',
    fontWeight: 600,
    lineHeight: '1.05rem',
    letterSpacing: '-0.009rem',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  },
  inputLabel: {
    fontSize: '0.75rem',
    fontWeight: 500,
    lineHeight: '1.225rem',
    letterSpacing: '-0.015rem',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    fontFamily: defaultBodyFontFamily
  },
  display01Bold: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '1.05rem',
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  },
  display02Bold: {
    fontSize: '1.75rem',
    fontWeight: 900,
    lineHeight: '1.8375rem',
    letterSpacing: 'normal',
    fontStyle: 'normal',
    fontFamily: defaultHeaderFontFamily
  }
};
