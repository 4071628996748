import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Stack } from '@mui/material';
import { useForm, SubmitHandler, Resolver } from 'react-hook-form';
import { Button } from 'components/button';
import { OnboardingContext } from '../context/onboardingContext';
import { FormInput, FormPassword } from 'components/form';
import { theme } from 'themes/theme';
import { orgAuthValidator } from 'pages/auth/validators/authFormValidators';
import { yupResolver } from '@hookform/resolvers/yup';
import { getItem, setItem, removeItem } from 'utilities/helperFunc/localStore';
import Taficasalogo from 'assets/custom-svg/taficasatransparent.svg';

type FormValues = {
  firstname: string;
  lastname: string;
  password: string;
  email: string;
};

const GetStarted = () => {
  const context = useContext(OnboardingContext);
  const { goToStep } = context;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid },
    setValue
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: ''
    },
    resolver: yupResolver(orgAuthValidator) as unknown as Resolver<FormValues>
  });

  useEffect(() => {
    const savedEmail = getItem<string>('email');
    if (savedEmail) {
      setValue('email', savedEmail);
    }

    const timer = setTimeout(() => {
      removeItem('email');
    }, 10000);
    return () => clearTimeout(timer);
  }, [setValue]);

  const onSubmit: SubmitHandler<FormValues> = data => {
    setItem('email', data.email);
    goToStep(1);
  };

  return (
    <Box
      sx={{
        width: '34rem',
        margin: '0 auto',
        padding: '2.5rem',
        pt: '2rem'
      }}
    >
      <Box sx={{ width: { lg: '29.125rem' } }}>
        <Box
          width="inherit"
          sx={{
            mb: '2.5rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Box
            component="img"
            src={Taficasalogo}
            sx={{
              width: '2.125rem',
              height: 'auto',
              mb: '1.25rem'
            }}
          />
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.heading}`,
              mb: '0.24rem'
            }}
          >
            Getting Started
          </Typography>
          <Typography
            variant="bodyLargeMedium"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Create a TafiCasa account
          </Typography>
        </Box>

        <Box width="inherit" sx={{ mb: '1.75rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              gap="1.5rem"
            >
              <FormInput<FormValues>
                control={control}
                label="FIRST NAME"
                name="firstname"
              />
              <FormInput<FormValues>
                control={control}
                label="LAST NAME"
                name="lastname"
              />
            </Stack>

            <FormInput<FormValues>
              control={control}
              label="WORK EMAIL"
              name="email"
            />

            <FormPassword<FormValues>
              control={control}
              label="PASSWORD"
              name="password"
              checkPassword
            />

            <Button
              color="primary"
              size="lg"
              disabled={!isValid || !isDirty}
              text="Next"
              width="100%"
              styleOverrides={{ marginBottom: '0.25rem' }}
              loadingIcon={isSubmitting}
              submit
            />
          </form>
        </Box>

        <Box sx={{ textAlign: 'center', mt: 2 }}>
          <Typography
            variant="body1"
            sx={{
              color: ' #98A2B3'
            }}
          >
            Don&apos;t have an account?{' '}
            <Link
              to="/signup"
              style={{
                textDecoration: 'none',
                color: `${theme.palette.common.blueColor}`
              }}
            >
              <Typography
                variant="bodyMediumMedium"
                sx={{
                  marginLeft: '0.1875rem'
                }}
              >
                Sign up
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GetStarted;
