import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { Button } from 'components/button';
import { ReactComponent as Question } from 'assets/svg/question.svg';
import { OnboardingContext } from 'pages/onboarding/context/onboardingContext';
import { ReactComponent as ChevronLeftIcon } from 'assets/svg/chevron-left.svg';

const OnboardingNav = () => {
  const context = useContext(OnboardingContext);

  const { currentStep, goToStep } = context;
  const disabledSteps = [0, 4, 5];
  const isDisabled = disabledSteps.includes(currentStep);
  const handleBackClick = () => {
    if (currentStep > 0) {
      goToStep(currentStep - 1);
    }
  };

  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ padding: '0.75rem 1.8rem' }}
    >
      <Button
        color="grey"
        transparent
        size="sm"
        icon={ChevronLeftIcon}
        iconPosition="start"
        text="Back"
        onClick={handleBackClick}
        disabled={isDisabled}
        styleOverrides={{ border: '0px' }}
      />
      <Button
        color="grey"
        transparent
        size="sm"
        icon={Question}
        iconPosition="start"
        text="Help"
        styleOverrides={{
          '& svg': {
            width: '1rem',
            height: '1rem'
          }
        }}
      />
    </Stack>
  );
};

export default OnboardingNav;
