import React, { useContext, useState } from 'react';
import construction from 'assets/svg/construction.svg';
import barrel from 'assets/svg/barrel.svg';
import consumables from 'assets/svg/consumables.svg';
import line from 'assets/svg/list.svg';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { theme } from 'themes/theme';
import { orgStepFormValidator2 } from 'pages/onboarding/validators/onboardingValidators';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { OnboardingContext } from '../context/onboardingContext';
import { FormCheckGroup, FormInput } from 'components/form';
import LoadingWrapper from '../common/onboardingLoadingpage';

type FormValues = {
  product: string[];
  otherProduct: string;
};

const ProductInformation = () => {
  const context = useContext(OnboardingContext);
  const { data } = context;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      product: data.product,
      otherProduct: data.otherProduct
    },
    resolver: yupResolver(
      orgStepFormValidator2
    ) as unknown as Resolver<FormValues>
  });

  const watchProduct = watch('product', []);

  const onSubmit: SubmitHandler<FormValues> = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate('/login');
    }, 3000);

    setIsFormVisible(false);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' }, position: 'relative' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 10
          }}
        >
          <LoadingWrapper isLoading={isLoading} />
        </Box>
      )}
      {isFormVisible && (
        <Box width="inherit">
          <Box width="inherit" sx={{ mb: '2rem', textAlign: 'center' }}>
            <Typography
              variant="h5"
              sx={{ color: `${theme.palette.common.heading}` }}
            >
              Product Information
            </Typography>
            <Typography
              variant="bodyMediumRegular"
              sx={{ color: `${theme.palette.common.labelColor}` }}
            >
              What kind of goods do you deal in? Check all that apply.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormCheckGroup<FormValues>
              control={control}
              name="product"
              label=""
              setValue={(data: string[]) => setValue('product', data)}
              getValues={() => getValues('product')}
              checkData={checkData}
            />

            {watchProduct.includes('other') && (
              <FormInput<FormValues>
                control={control}
                name="otherProduct"
                label=""
              />
            )}

            <Button
              submit
              color="primary"
              size="lg"
              disabled={!isValid}
              text="Next"
              width="100%"
              styleOverrides={{ marginBottom: '0.25rem' }}
            />
            <Button
              color="grey"
              transparent
              size="lg"
              onClick={() => navigate('/previous-step')}
              text="Back"
              width="100%"
            />
          </form>
        </Box>
      )}
    </Box>
  );
};

export default ProductInformation;

const checkData = [
  {
    image: construction,
    heading: 'Construction',
    value: 'construction',
    subHeading: 'Essential materials for building projects e.g cement'
  },
  {
    image: consumables,
    heading: 'Consumables',
    value: 'consumables',
    subHeading: 'Everyday essentials and perishable goods e.g beverages'
  },
  {
    image: barrel,
    heading: 'Petroleum products',
    value: 'distributor',
    subHeading: 'Fuels, lubricants, and related products'
  },
  {
    image: line,
    heading: 'Other',
    value: 'other',
    subHeading: 'Receive and endorse Product receipts from merchants'
  }
];
