import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { OnboardingContext } from '../context/onboardingContext';
import { theme } from 'themes/theme';
import Taficasalogo from 'assets/custom-svg/flux-logo.svg';

const CompleteProfile = () => {
  const { goToStep } = useContext(OnboardingContext);

  return (
    <Box
      sx={{
        width: '34rem',
        margin: '0 auto',
        padding: '2.5rem',
        pt: '10rem'
      }}
    >
      <Box sx={{ width: { lg: '29.125rem' } }}>
        <Box
          sx={{
            mb: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src={Taficasalogo}
            alt="Profile Complete"
            style={{ width: '3rem' }}
          />
        </Box>

        <Box
          width="inherit"
          sx={{
            mb: '1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h6Bold"
            sx={{
              color: `${theme.palette.common.heading}`,
              mb: '0.5rem'
            }}
          >
            Complete Your Profile
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Set up your flux profile to tailor your experience
          </Typography>
        </Box>

        <Box width="inherit" sx={{ mb: '0.75rem' }}>
          <Button
            color="primary"
            size="md"
            onClick={() => goToStep(6)}
            text="Get Started"
            width="100%"
            styleOverrides={{ marginBottom: '0rem' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteProfile;
