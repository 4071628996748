import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import Divider from 'components/common/divider';
import { FormAutocomplete, FormInput } from 'components/form';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { theme } from 'themes/theme';
import { OnboardingContext } from '../context/onboardingContext';
import { orgStepFormValidator5 } from '../validators/onboardingValidators';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import Taficasalogo from 'assets/custom-svg/taficasatransparent.svg';

type FormValues = {
  emails?: string[];
  firstname?: string;
  lastname?: string;
};

const Invite = () => {
  const context = useContext(OnboardingContext);
  const { goToStep } = useContext(OnboardingContext);
  const { handleNextStep, data, changeBtn, setChangeBtn } = context;

  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty, isSubmitting }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      emails: data.emails,
      firstname: '',
      lastname: ''
    },
    resolver: yupResolver(
      orgStepFormValidator5
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep(data);
    ShowInfo({
      message: 'Invites sent!',
      subText: '',
      type: 'success',
      componentType: 'toast',
      hideProgressBar: false
    });
    setChangeBtn(!changeBtn);
  };

  return (
    <Box sx={{ width: { lg: '29.25rem' } }}>
      <Box
        width="inherit"
        sx={{
          mb: '2rem',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Box
          component="img"
          src={Taficasalogo}
          sx={{
            width: '2.125rem',
            height: 'auto',
            mb: '1.25rem'
          }}
        />
        <Typography
          variant="h6Bold"
          sx={{
            color: `${theme.palette.common.heading}`,
            mb: '0.5rem'
          }}
        >
          Invite to Organization
        </Typography>
        <Typography
          variant="bodyMediumRegular"
          sx={{
            color: `${theme.palette.common.labelColor}`
          }}
        >
          {isValid
            ? 'Give selected team members a seat in your organization. Make sure the emails are accurate'
            : 'Welcome to ****** HQ. Invite team members and make sure the emails are accurate'}
        </Typography>
      </Box>

      <Box width="inherit">
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            gap="1.5rem"
          >
            <FormInput<FormValues>
              control={control}
              label="FIRST NAME"
              name="firstname"
            />

            <FormInput<FormValues>
              control={control}
              label="LAST NAME"
              name="lastname"
            />
          </Stack>

          <FormAutocomplete<FormValues>
            control={control}
            multiple={true}
            name="emails"
            label="EMAILS"
            options={[]}
          />

          <Button
            color="primary"
            size="lg"
            text={changeBtn ? 'Go to Dashboard' : 'Invite'}
            styleOverrides={{
              padding: '0.75rem 1rem',
              width: '100%'
            }}
            submit={!changeBtn ? true : false}
            disabled={!isValid || !isDirty}
            onClick={() => {
              changeBtn ? navigate('/') : null;
            }}
            loadingIcon={isSubmitting}
          />
        </form>
      </Box>

      <Divider mt="1.5rem" fontWeight={400} />

      <Box width="inherit" sx={{ mt: '1.5rem' }}>
        <Button
          color="grey"
          transparent
          size="lg"
          text={changeBtn ? 'Invite more' : 'Skip this step'}
          styleOverrides={{
            padding: '0.75rem 1rem',
            width: '100%'
          }}
          onClick={() => goToStep(4)}
          // onClick={() => {
          //   changeBtn ? setChangeBtn(!changeBtn) : navigate('/');

          // }}
        />
      </Box>
    </Box>
  );
};

export default Invite;
