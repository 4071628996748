import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { Row, flexRender } from '@tanstack/react-table';
import { IndeterminateCheckbox, OptionsButton } from '../tableButtons';

interface NestedTableProps<TableDataT> {
  row: Row<TableDataT>;
  optionsButton?: boolean;
  onClickRowCallback: (rowData: TableDataT) => void;
}

export default function NestedTable<TableDataT>({
  row,
  optionsButton,
  onClickRowCallback
}: NestedTableProps<TableDataT>) {
  return (
    <>
      {row.subRows &&
        row.subRows.map(subRow => {
          return (
            <TableRow
              key={subRow.id}
              sx={{
                background: '#F9FAFC'
              }}
            >
              <TableCell
                sx={{
                  borderBottom: '.0625rem solid #F5F6F7',
                  height: '4.188rem',
                  display: 'table-cell',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: `${subRow.depth * 2}rem`,
                  left: '0rem',
                  opacity: 1,
                  position: 'sticky',
                  zIndex: 1,
                  backgroundColor: 'inherit'
                }}
              >
                <IndeterminateCheckbox
                  {...{
                    checked: subRow.getIsSelected(),
                    disabled: !subRow.getCanSelect(),
                    indeterminate: subRow.getIsSomeSelected(),
                    onChange: subRow.getToggleSelectedHandler()
                  }}
                />
              </TableCell>
              {subRow.getVisibleCells().map(cell => {
                const value = cell.getValue();
                return (
                  <TableCell
                    key={cell.id}
                    sx={{
                      borderBottom: '.0625rem solid #F5F6F7',
                      width: `${cell.column.getSize() / 16}rem`,
                      zIndex: 0,
                      position: 'relative',
                      ...(cell.column.columnDef.enablePinning && {
                        left: '5rem',
                        opacity: 1,
                        position: 'sticky',
                        zIndex: 1,
                        backgroundColor: 'inherit',
                        boxShadow: '-2px 0 2px -2px lightgray inset'
                      })
                    }}
                    onClick={() => onClickRowCallback(subRow.original)}
                  >
                    {value === '' || value === null || value === undefined
                      ? '-'
                      : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                  </TableCell>
                );
              })}
              {optionsButton && (
                <TableCell
                  sx={{
                    borderBottom: '.063rem solid #F5F6F7',
                    marginRight: '.9rem',
                    right: '0rem',
                    opacity: 0.95,
                    position: 'sticky',
                    width: '3.125rem',
                    zIndex: 1,
                    backgroundColor: '#fff',
                    cursor: 'pointer'
                  }}
                  onClick={() => onClickRowCallback(subRow.original)}
                >
                  <OptionsButton />
                </TableCell>
              )}
            </TableRow>
          );
        })}
    </>
  );
}
