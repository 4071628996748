import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const ImageLoader: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: '100px',
        height: '10px'
      }}
    >
      <CircularProgress
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          color: 'grey.400'
        }}
        size={70}
        thickness={2}
        variant="determinate"
        value={100}
      />

      <CircularProgress
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          color: 'primary.main'
        }}
        size={70}
        thickness={2}
      />

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '36px',
          height: '36px'
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
        />
      </Box>
    </Box>
  );
};

export default ImageLoader;
