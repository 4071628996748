import { createTheme, outlinedInputClasses, Theme } from '@mui/material';
import { TypographyTheme } from './typography';
import { ButtonTheme } from './button';
import { AccordionTheme } from './accordion';

export const fontFamily2 = 'Inter';

export const Nohemi = 'Nohemi ExtBd';

declare module '@mui/material/styles' {
  interface Theme {
    common: CommonColors;
  }

  interface CommonColors {
    black: string;
    white: string;
    background: string;
    [key: string]: string;
  }

  interface Palette {
    custom: Palette['primary'];
  }

  interface PaletteOptions {
    custom?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    bodyMedium: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodyMedium?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    custom: true;
  }
}

export const theme: Theme = createTheme({
  palette: {
    common: {
      black: '#000000',
      white: '#ffffff',
      card: '#FEFEFE',
      cardBorder: '#F5F6F7',
      labelColor: '#667085',
      textColor: '#98A2B3',
      btnColor: '#3E7DF8',
      heading: '#344054',
      subheading: '#475467',
      progressGreen: '#3FB7A8',
      progressBackground: '#F2F4F7',
      dividerColor: '#D0D5DD',
      error: '#F53F39',
      redColor: '#CB1A14',
      blueColor: '#2563EB',
      transparent: 'rgba(0, 0, 0, 0)',
      lightTransparent: '#C4D8FF33',
      logoColor: '#353B45',
      boxShadow: '0px 0px 0px 3px rgba(36, 100, 241, 0.15)',
      btnBackground: '#C1D3F7',
      appBackground: '#FCFCFD',
      layoutBackground:
        'linear-gradient(180deg, rgba(233, 242, 255, 0.04) 0%, rgba(200, 226, 250, 0.04) 37.3%, rgba(169, 204, 242, 0.04) 51.02%, rgba(126, 168, 216, 0.04) 70.77%, rgba(30, 136, 234, 0.04) 91.06%, rgba(74, 134, 189, 0.04) 107.52%)',
      authBackground:
        'linear-gradient(180deg, rgba(212, 229, 255, 0.06) 0%, rgba(200, 226, 250, 0.06) 34%, rgba(169, 204, 242, 0.06) 46.5%, rgba(126, 168, 216, 0.06) 64.5%, rgba(30, 136, 234, 0.06) 83%, rgba(1, 46, 86, 0.06) 98%), #FFF'
    },
    primary: {
      light: '#C1D3F7',
      main: '#3E7DF8',
      dark: '#3E7DF8'
    }
  },
  typography: TypographyTheme,
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#6F7E8C'
          },
          '& .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input': {
            fontFamily: fontFamily2,
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#EAECF0',
          border: '.094rem solid transparent',
          borderRadius: '.5rem'
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: '#EAECF0',
            border: '.094rem solid transparent',
            borderRadius: '.5rem'
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: 'transparent',
            border: '.094rem solid'
          }
        }
      }
    },
    MuiButton: ButtonTheme,
    MuiBadge: {
      styleOverrides: {
        root: {
          '& .MuiBadge-badge': {
            color: '#04326B',
            backgroundColor: '#F4F9FF',
            borderRadius: '0.75rem'
          },
          '& .MuiBadge-colorSuccess': {
            backgroundColor: '#04802E',
            borderRadius: '0.75rem'
          },
          '& .MuiBadge-dot': {
            width: '0.5rem',
            height: '0.5rem',
            backgroundColor: '#04802E',
            borderRadius: '0.5rem'
          }
        }
      }
    },
    MuiAccordion: AccordionTheme
  }
});
