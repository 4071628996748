import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import Loader from '../../../components/loader/onboardingLoader';
import Taficasalogo from 'assets/custom-svg/flux-logo.svg';
import { OnboardingContext } from 'pages/onboarding/context/onboardingContext';

const fadeInAnimation = `
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface LoadingWrapperProps {
  isLoading: boolean;
  loader?: React.ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({
  isLoading,
  loader
}) => {
  const [isInView, setIsInView] = useState(false);
  const { handleNextStep } = useContext(OnboardingContext);

  const observerCallback = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      setIsInView(true);
    } else {
      setIsInView(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5
    });

    const targetElement = document.getElementById('loading-wrapper');
    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView && isLoading) {
      const timer = setTimeout(() => {
        handleNextStep({});
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isInView, isLoading, handleNextStep]);

  if (isLoading) {
    return (
      <Box
        id="loading-wrapper"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '98vh',
          display: 'flex',
          paddingBottom: '2rem',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '1.985rem',
          opacity: 0,
          animation: isInView ? 'fadeIn 2s forwards' : 'none'
        }}
      >
        <style>{fadeInAnimation}</style>
        {loader || <Loader src={Taficasalogo} alt="Loading" />}
      </Box>
    );
  }

  return null;
};

export default LoadingWrapper;
