import React, { useContext, useEffect } from 'react';
import {
  Typography,
  SvgIcon,
  Stack,
  Button as MUIButton,
  Avatar
} from '@mui/material';
import { theme } from 'themes/theme';
import { colorTheme } from 'themes/colors';
import { OnboardingContext } from 'pages/onboarding/context/onboardingContext';

const OnboardingNav = ({ logStep }: { logStep: (step: number) => void }) => {
  const context = useContext(OnboardingContext);

  const { currentStep, goToStep } = context;

  useEffect(() => {
    logStep(currentStep);
  }, [currentStep, logStep]);

  const showStep = (
    isCurrent: boolean,
    isPrev: boolean,
    isNext: boolean,
    element: CrumbProps,
    index: number
  ) => {
    const avatarBgColor = isCurrent
      ? theme.palette.common.white
      : 'rgba(255, 255, 255, 0.2)';

    const avatarTextColor = isCurrent
      ? colorTheme.grey600
      : theme.palette.common.white;

    return (
      <MUIButton
        key={index}
        variant="outlined"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          textTransform: 'none',
          backgroundColor: isCurrent
            ? 'rgba(255, 255, 255, 0.2)'
            : 'transparent',
          border: `1px solid ${theme.palette.common.transparent}`,
          borderRadius: '0.375rem',
          padding: '0.5rem 0.75rem 0.5rem 0.75rem',
          marginBottom: '0.5rem',
          minWidth: '7.5rem',
          cursor: 'pointer',
          '&:hover': {
            borderColor: theme.palette.common.transparent
          }
        }}
        onClick={() => {
          if (isPrev && currentStep > 0) {
            goToStep(currentStep - 1);
          }
        }}
      >
        {!isPrev && !isCurrent && (
          <Avatar
            sx={{
              bgcolor: avatarBgColor,
              color: avatarTextColor,
              width: '1.25rem',
              height: '1.25rem',
              marginRight: '0.6rem',
              fontSize: '0.9rem'
            }}
          >
            {element.id}
          </Avatar>
        )}

        {isCurrent && (
          <Avatar
            sx={{
              bgcolor: avatarBgColor,
              color: avatarTextColor,
              width: '1.25rem',
              height: '1.25rem',
              marginRight: '0.6rem',
              fontSize: '0.9rem'
            }}
          >
            {element.id}
          </Avatar>
        )}

        {isPrev && !isCurrent && (
          <SvgIcon sx={{ marginRight: '0.6rem' }}>
            <svg
              width="1.25rem"
              height="1.25rem"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                width="16"
                height="16"
                rx="8"
                fill={colorTheme.primary300}
              />
              <g clipPath="url(#clip0_2441_467)">
                <path
                  d="M12.0556 5.33301L7.16666 10.2219L4.94444 7.99967"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2441_467">
                  <rect
                    width="0.9rem"
                    height="0.9rem"
                    fill="white"
                    transform="translate(3.16667 2.6665)"
                  />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        )}
        <Typography
          variant="bodyLargeMedium"
          sx={{
            color: colorTheme.grey300,
            fontWeight: 'normal'
          }}
        >
          {element.text}
        </Typography>
      </MUIButton>
    );
  };

  return (
    <Stack
      direction="column"
      justifyContent="left"
      alignItems="left"
      sx={{
        padding: '0rem 1.8rem 0rem 0rem'
      }}
    >
      {crumbs.map((element, index) => {
        const isCurrent = currentStep === index;
        const isPrev = index < currentStep;
        const isNext = index > currentStep;
        return showStep(isCurrent, isPrev, isNext, element, index);
      })}
    </Stack>
  );
};

export default OnboardingNav;

type CrumbProps = {
  id: number;
  text: string;
};

const crumbs: CrumbProps[] = [
  { id: 1, text: 'Get Started' },
  { id: 2, text: 'Email Verification' },
  { id: 3, text: 'Setup Organization' },
  { id: 4, text: 'Invite to Organization' }
];
