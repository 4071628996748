import React, { useState, useMemo } from 'react';
import * as am5 from '@amcharts/amcharts5';
import { IComponentDataItem } from '@amcharts/amcharts5/.internal/core/render/Component';
import { Box, Paper, Stack } from '@mui/material';
import MapChart from 'components/charts/mapChart';
import { DateRangeType, Nav } from './chartsNav';
import AnalyticsDataTable from '../tables/analyticsTable';
import { useGetClosedProductReceiptsHoldersQuery } from 'services/api/analyticsApi';
import { formatLargeAmount } from 'utilities/helperFunc/formatter';
import { formattedDate } from 'utilities/helperFunc/formatter';
import { getDateRange } from 'utilities/helperFunc/getDateRange';

interface StateDataContext {
  id: string;
  value: number;
  name: string;
}

type MapDataType = {
  id: string;
  value: number;
  formatted_value: string;
};

type TableDataType = {
  id?: string;
  logo: string;
  name: string;
  value: string;
  quantity?: string;
  unit?: string;
};

const defaultDateRange = getDateRange('This Month');
const TopDealerLocationsChart = () => {
  const [dateRange, setDateRange] = useState<DateRangeType>({
    startDate: defaultDateRange.start,
    endDate: defaultDateRange.end,
    key: 'selection',
    granularity: 'daily'
  });

  const { data: closedProductReceiptHoldersData } =
    useGetClosedProductReceiptsHoldersQuery({
      params: {
        start: dateRange.startDate
          ? formattedDate(dateRange.startDate, 'YYYY-MM-DD')
          : '2024-01-01',
        ...(dateRange.endDate && {
          end: formattedDate(dateRange.endDate, 'YYYY-MM-DD')
        }),
        holder_type: 'distributor'
      }
    });

  const [selectedState, setSelectedState] = useState('this location');

  const handleMapState = (data: am5.DataItem<IComponentDataItem>) => {
    const tableData = data.dataContext as StateDataContext;
    if (tableData.name == 'Abuja Federal Capital Territory') {
      setSelectedState('Abuja');
    } else {
      setSelectedState(tableData.name);
    }
  };

  const transformedMapData = useMemo(() => {
    if (closedProductReceiptHoldersData) {
      const result = closedProductReceiptHoldersData.reduce(
        (acc: MapDataType[], item) => {
          const stateCode = item.location.state_map_code;
          const totalValue = Number(item.total_value);

          // Check if state map code exists already
          const existingState = acc.find(entry => entry.id === stateCode);
          if (existingState) {
            existingState.value += totalValue;
          } else {
            acc.push({
              id: stateCode,
              value: totalValue,
              formatted_value: formatLargeAmount(totalValue, true, true, 2)
            });
          }

          return acc;
        },
        []
      );
      return result;
    }
    return [];
  }, [closedProductReceiptHoldersData]);

  const transformedTableData: TableDataType[] = useMemo(() => {
    return (
      closedProductReceiptHoldersData?.map(item => ({
        id: item.location.administrative_area,
        name: item.profile.name,
        logo: item.profile.logo,
        value: item.profile.public_id,
        unit: formatLargeAmount(Number(item.total_value), true, true, 2)
      })) || []
    );
  }, [closedProductReceiptHoldersData]);

  const mapTableData =
    transformedTableData &&
    transformedTableData.filter(item => item.id === selectedState);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '1.25rem',
        borderRadius: '0.75rem',
        backgroundColor: '#FFF',
        border: '.0625rem solid #F5F6F7',
        mt: '2rem',
        boxShadow: '0px 1.5px 4px -1px rgba(16, 25, 40, 0.03)'
      }}
    >
      <Nav
        header="Dealers by Location"
        caption="See top dealers by location"
        setDateRange={setDateRange}
        showButton
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        height="inherit"
        sx={{
          gap: {
            md: '1.5rem',
            lg: '2rem'
          }
        }}
      >
        <Box
          sx={{
            width: {
              md: '58%',
              lg: '63%',
              xl: '70%'
            },
            background: '#F8FBFE',
            height: '30rem'
          }}
        >
          <MapChart
            mapData={transformedMapData}
            onClickState={mapData => handleMapState(mapData)}
          />
        </Box>
        <Box
          sx={{
            height: '30rem',
            width: {
              md: '40%',
              lg: '35%'
            },
            maxWidth: '30rem'
          }}
        >
          <AnalyticsDataTable
            heading="Dealers"
            subHeading={`Top dealers in
              ${
                selectedState === 'Abuja Federal Capital Territory'
                  ? 'FCT'
                  : selectedState
              }`}
            tableData={mapTableData}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
export default TopDealerLocationsChart;
