import React from 'react';
import { Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactComponent as Bank } from 'assets/svg/bank.svg';
import { ReactComponent as Flag } from 'assets/svg/flag.svg';
import { Table } from 'components/table';
import { OrganizationType } from 'services/enums/organization';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import { useNavigate } from 'react-router-dom';
import { Chip } from 'components/chip';

const ProductReceiptsTable = ({
  data,
  organizationType,
  fromBranchVault,
  loading
}: {
  data: MiniProductReceiptApiData[];
  organizationType: OrganizationType;
  branchId?: string;
  fromBranchVault?: boolean;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<MiniProductReceiptApiData>();
  const columns = [
    columnHelper.accessor('product_receipt_id', {
      header: 'PRC CODE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric',
      enablePinning: true,
      size: 100
    }),
    columnHelper.accessor('product', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue().name}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_quantity', {
      header: 'QUANTITY',
      cell: info => {
        const unit = info.row.original.product?.unit;
        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {`${formattedNumber(info.getValue(), false)} ${unit ? `(${unit})` : ''}`}
          </Typography>
        );
      },
      footer: info => info.column.id,
      enableSorting: false
    }),
    columnHelper.accessor('quoted_price', {
      header: 'TOTAL VALUE',
      cell: info => {
        const quotedPrice = info.getValue();
        const totalQuantity = info.row.original.total_quantity;
        const totalValue = totalQuantity * quotedPrice;

        return (
          <Typography variant="bodyMediumMedium" color="#475467">
            {formattedNumber(totalValue, true)}
          </Typography>
        );
      },
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('holders', {
      header: 'ISSUE TYPE',
      cell: info => (
        <Chip
          label={info.getValue().length > 1 ? 'Intermediary' : 'Direct'}
          size="sm"
        />
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<MiniProductReceiptApiData>[];
  // Cast is a workaround due to issues with tansatck table
  // See https://github.com/TanStack/table/issues/4302

  const updateColumnDefForOrgs = (organizationType: OrganizationType) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>,
        columnHelper.accessor('holders', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()[info.getValue().length - 1]}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    } else if (organizationType === OrganizationType.DISTRIBUTOR) {
      columns.splice(
        1,
        0,
        columnHelper.accessor('merchant', {
          id: 'merchant',
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<MiniProductReceiptApiData>
      );
    }
  };

  updateColumnDefForOrgs(organizationType);

  const formattedData = [...data].reverse();

  const menuItems = [
    {
      image: Bank,
      text: 'View Details',
      onClickMenuItem: (rowData: MiniProductReceiptApiData) => {
        navigate(`/product-receipts/${rowData.product_receipt_id}/details`);
      },
      fill: '#98A2B3'
    },
    {
      image: Bank,
      text: 'Start Workflow',
      onClickMenuItem: (rowData: MiniProductReceiptApiData) => {
        navigate(`/product-receipts/${rowData.product_receipt_id}/workflow`);
      },
      fill: '#98A2B3'
    },
    { image: Flag, text: 'Report Problem', fill: '#F86F66' }
  ];

  const getTableTitle = () => {
    if (fromBranchVault) {
      return 'Branch Page';
    } else if (organizationType === OrganizationType.MERCHANT) {
      return data[0]?.holders.at(-1);
    } else {
      return data[0]?.merchant;
    }
  };

  return (
    <Table<MiniProductReceiptApiData>
      hover
      columns={columns}
      data={formattedData}
      optionsButton
      menuItemProps={menuItems}
      enableFilter={false}
      filterColumns={['product']}
      searchPlaceholderText="Search with product receipt code, product etc"
      showMenu
      filterTabs={['product', 'merchant']}
      loadingTable={loading}
      title={
        loading || formattedData.length === 0 ? undefined : getTableTitle()
      }
      subtitle={
        loading || formattedData.length === 0
          ? undefined
          : `All Product Receipts’s  for ${getTableTitle()}`
      }
      getRowCanExpand={() => false}
      getSubRows={row =>
        row.related_product_receipts
          ? (row.related_product_receipts as MiniProductReceiptApiData[])
          : []
      }
    />
  );
};

export default ProductReceiptsTable;
