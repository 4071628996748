import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { OnboardingContext } from './context/onboardingContext';

const OnboardingPage = () => {
  const context = useContext(OnboardingContext);
  const { steps, currentStep } = context;

  return (
    <Box
      sx={{
        width: '34rem',
        margin: {
          md: '0 auto'
        },
        padding: '0.9rem',
        pt: '2rem'
      }}
    >
      {steps[currentStep]?.element}
    </Box>
  );
};

export default OnboardingPage;
